import React from "react"
import SEO from "../../components/SEO"
import { ResetPassword } from "./activate"

export default function ForgotPasswordPage(): React.ReactNode {
  return (
    <>
      <SEO title="Forgot your password" />
      <ResetPassword mode="forgot" />
    </>
  )
}
